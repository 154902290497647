.tranAnimation{
    animation-name: tranImgSlide;
    animation-duration: 20s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

/* .mentItemAnimation{
    animation-name: fadeinout;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}
@keyframes fadeinout {
    0%, 100% {
        opacity: 0;
    } 
    50% {
        opacity: 1;
    }
} */



@keyframes tranImgSlide {
    0%{
        transform: translateX(0%);
    }    
    100%{
        transform: translateX(-50%);
    }
}

/* .mainText{
    animation: fade-down 1s;
}

.mainCap{
    animation: fade-up 1s;
} */
@keyframes fade-up {
    0% {
        opacity: 0;
        transform: translate(30px) scale(0.9);
    }

    100% {
        opacity: 1;
        transform: translateY(0px) scale(1);
    }
}

@keyframes fade-down{
    0%{
        opacity: 0;
        transform: translate(-30px) scale(0.9);
    }
    100%{
        opacity: 1;
        transform: translateY(0px) scale(1);
    }
}