.bg{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('/public/assets/samdo_alpha_main_banner.png');
}

.img-width{
    width: 20%;
}

.flex-set{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.pad-bt-top{
    padding-top: 2%;
    padding-bottom: 2%;
}
.main-font{
    color: #ffffff;
    font-size: 2em;
    font-family: "songMyung";
    padding-bottom: 1%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@font-face {
    font-family: "songMyung";
    font-weight: normal;
    src: url("./fonts/SongMyung-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "tmon";
    font-weight: normal;
    src: url("./fonts/TMONBlack.ttf") format("truetype");
}

.bt-set{
    background-color: #000a61b0;
    font-size: 1.7em;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 11%;
    height: 6vh;
    font-family: "tmon";
    border: 0;
    color: #ffffff;
}

.bt-set:hover{
    cursor: pointer;
}

.whiteLogo{
    width: 20%;
    margin-right: 10px;
}


@media (max-width:1024px) {
    .bt-set {
        background-color: #000a61b0;
        font-size: 1.7em;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20%;
        height: 6vh;
        font-family: "tmon";
        border: 0;
        color: #ffffff;
    }
}

@media (max-width:768px){
    .bt-set {
            background-color: #000a61b0;
            font-size: 1.7em;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20%;
            height: 6vh;
            font-family: "tmon";
            border: 0;
            color: #ffffff;
        }
}

@media (max-width:430px) {
    .bt-set {
        background-color: #000a61b0;
        font-size: 1.7em;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35%;
        height: 6vh;
        font-family: "tmon";
        border: 0;
        color: #ffffff;
    }

    .whiteLogo {
        width: 28%;
        margin-right: 10px;
    }
}

@media (max-width:425px) {
    .bt-set {
        background-color: #000a61b0;
        font-size: 1.7em;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35%;
        height: 6vh;
        font-family: "tmon";
        border: 0;
        color: #ffffff;
    }

        .whiteLogo {
            width: 28%;
            margin-right: 10px;
        }
}

@media (max-width:375px) {
    .bt-set {
        background-color: #000a61b0;
        font-size: 1.7em;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 46%;
        height: 6vh;
        font-family: "tmon";
        border: 0;
        color: #ffffff;
    }
}

@media (max-width:320px) {
    .bt-set {
        background-color: #000a61b0;
        font-size: 1.7em;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45%;
        height: 6vh;
        font-family: "tmon";
        border: 0;
        color: #ffffff;
    }

        .whiteLogo {
            width: 35%;
            margin-right: 10px;
        }
}
